@media (min-width: 992px )  {
  *{
    padding: 0;
    margin: 0;    
  }

 nav{
  display: flex; 
  align-self: flex-end;  
  color: black;
  text-decoration: none;
  padding: 3rem 2rem;
  text-shadow: 2px 2px 5px rgb(61, 164, 29);
  font-size:1.2rem;
  font-weight:500;
  
} 


h1{
   text-shadow: 2px 2px 5px rgb(61, 164, 29)
}

h2{
  font-size: 0.5rem;
  text-shadow: 2px 2px 5px rgb(61, 164, 29)
  
}

.titulo2{
  text-align: center;
  max-width: 100%;
  text-shadow: 2px 2px 5px rgb(61, 164, 29);
  font-size:3.9rem;  
  color:white;  
  font-family: 'Lobster', cursive;  
  padding: 3rem 2rem 2rem 6rem;  
}

#inicio{ 
  display: flex;
  background: rgb(61, 164, 29);
  padding: 3rem 3rem; ;
  margin: 5rem 0rem 0rem 4rem; 
  height: 34rem;
  width: 1200px;  
}

#imginicio{
  padding: 0rem;
  height: 480px;
  width: 800px;  
}

#imgcal{
  margin: 3rem 8rem ;
  height: 240px;
  width: 250px; 
}

#imgt{
  height: 140px;
  width: 550px;
}

#resaltar{
  color:#000000;
  text-shadow: 2px 2px 5px rgb(61, 164, 29);
  font-size:1.2rem;
  font-weight:500;
}

#turnos{
  padding: 1rem 0rem;
  font-size: 20px;
}

.turno{
  padding:2rem 0rem;
  margin: 2rem 0rem;
}

.turno a{
  
    text-decoration: none;
    font-size: 1rem ;
    color: white;
  
}

.btn2{
  margin:0rem 12rem;
}
#nosotros{
  display: flex;
  padding: 0rem 5rem;
  border: 2rem;
  margin-top: 0rem;  
}

.imgnosotros{
  margin: 5rem 5rem 0rem 5rem;
  width: 400px;
  height: 420px;
  border-style: outset;
  border-width: 5px;
 
}

.lista1::marker{ 
  font-size: 1.5rem;
  color: rgb(61, 164, 29) ;
}

#lista1{
  padding: 10rem 3rem 0rem 3rem;
  font-size: 25px;
}

#contenedorcontacto{
  display: flex;
  padding: 3rem 0rem 0rem 0rem;
  border: #272a27;
  width: 1300px;
  height: 600px;
}

.formulario{  
  margin: 3px 4rem;
  padding: 2px;
  width: 400px; 
}

.fondoform{  
  height: 450px;
  background-image: url(./assets/img/fondo-verde-42242525.jpg);
  margin: 0rem 5rem 0rem 8rem;
  padding: 2rem 0rem;
  width: 800px;
}

.boton{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0rem 0rem 15rem;
  width: 10rem;
  }

 .message{ 
  padding: 0.7rem;
    margin: 1rem;
    text-Align: center;
    color: RGB(25, 135, 84);
    font-Weight: bold;
    font-Size:1.3rem;
 }

#contacto{
   padding: 1rem;  
}



#comunicate{  
  margin: 0rem 6rem 0rem 2rem;
  padding: 1rem;
  border: rgb(36, 32, 32);
  background-color:rgba(239, 239, 239, 0.895);
  height: 450px;
  width: 570px;
  position: relative;
}

#comunicate::after{  
  border: #000000;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: #000000;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #0f9b0f, #000000);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #0f9b0f, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  content: ' ';  
  width: 100%;
  height: 10%;
  filter:blur(10px);
  z-index: -1;
}

.iconos{
  font-size: 1.2rem;
  font-weight: 30px;
  color: gray;
  transition: 0.8s;
  margin: 0rem 0rem 0rem 3rem;

}

.iconos:hover{
  color: rgb(61, 164, 29)
}

.iconos a{
  text-decoration: none;
  font-size: 1rem ;
  color: gray
}

.iconos a:hover{
color:rgb(61, 164, 29) ;
}

#letra{
  font-weight: 800;
}

#ubicacion{  
  font-size: 2rem; 
}


#ubicacion a{
  text-decoration: none;
  color: gray ;
  font-size: 1rem;
}

#ubicacion a:hover{
  color: rgb(61, 164, 29) ;
}

.containers{
  margin: 0rem;
  border: rgb(36, 32, 32);
  background: linear-gradient(to bottom right,white, rgb(61, 164, 29));
  height: 100px;
  width: 1200px;
  position: relative;  
}

.containers::after{
  
  border: #000000;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: #000000;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #0f9b0f, #000000);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #0f9b0f, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


  content: ' '; 
  width: 100%;
  height: 100%;
  filter:blur(10px);
  z-index: -1;
}

.sobre{ 
  height: 250px;
  padding: 1rem;
  margin: 0rem ;
  }

  .ubc{
    padding: 1rem 0rem;
    margin: 1rem 0rem;
  }
}


  @media (min-width: 768px ) and (max-width: 991px) {
       
    nav{
      display: flex; 
      align-self: flex-end;  
      color: black;
      text-decoration: none;
      padding: 3rem 2rem;
      text-shadow: 2px 2px 5px rgb(61, 164, 29);
      font-size:1.2rem;
      font-weight:500;      
    } 
    
    h1{
       text-shadow: 2px 2px 5px rgb(61, 164, 29)
    }
    
    h2{
      font-size: 0.5rem;
      text-shadow: 2px 2px 5px rgb(61, 164, 29)      
    }
    
    .titulo2{
      text-align: center;
      max-width: 100%;    
      text-shadow: 2px 2px 5px rgb(61, 164, 29);
      font-size:3rem;      
      color:white;      
      font-family: 'Lobster', cursive;      
      padding: 7rem 3rem 3rem 6rem;    
      
    }
    
    #inicio{    
      display: flex;
      background: rgb(61, 164, 29);
      padding: 7.5rem 1rem; ;
      margin: 5rem  0rem 0rem 0rem;
      width: 900px;
      height: 38rem;     
    }
    
    #imginicio{
      padding: 0rem  ;
      height: 480px;
      width: 1000px;  
    }
    
    #nosotros{
      padding: 0rem 5rem;
      border: 2rem;
      margin-top: 0rem;
      display: flex;
      width: 900px;      
    }
        
    .imgnosotros{
      margin: 5rem 5rem 0rem 5rem;
      width: 400px;
      height: 420px;
      border-style: outset;
      border-width: 5px;     
    } 
    
    .lista1::marker{      
      font-size: 1rem;
      color: rgb(61, 164, 29) ;   
    }
    
    #lista1{
      padding: 6rem 3rem 0rem 3rem;
      font-size: 22px;
    }
    
    #contenedorcontacto{
      display: flex;
      padding: 8rem 0rem 0rem 0rem;
      border: #272a27;
      width: 992px;
      height: 600px;   
    }
    
    .formulario{      
      margin: 3px 2rem;
      padding: 2px;
      width: 400px;      
    }
    
    .fondoform{      
      height: 450px;
      background-image: url(./assets/img/fondo-verde-42242525.jpg);
      margin: 0rem 2rem;
      padding: 2rem 0rem;
      width: 700px;
    }
    
    .boton{
      display: flex;
      align-items: center;
      justify-content: center;    
      padding: 2rem 0rem 0rem 15rem;
      width: 10rem;
      }
    
     .message{ 
      padding: 0.2rem;
        margin: 0.3rem;
        text-Align: center;
        color: rgb(25, 135, 84);
        font-Weight: bold;
        font-Size:1rem;
     }
    
    #contacto{
       padding: 2rem 2rem 0rem;
      
    }   
    
    #comunicate{
  
      margin: 0rem 2rem;
      padding: 1rem;
      border: rgb(36, 32, 32);
      background-color:rgba(239, 239, 239, 0.895);
      height: 450px;
      width: 700px;
      position: relative;
    }
    
    #comunicate::after{
      
      border: #000000;
      position: absolute;
      bottom: 0px;
      left: 0px;
      background: #000000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0f9b0f, #000000);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0f9b0f, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
    
      content: ' ';
      
      width: 100%;
      height: 10%;
      filter:blur(10px);
      z-index: -1;
    }
    
    .iconos{
      font-size: 1.2rem;
      font-weight: 30px;
      color: gray;
      transition: 0.8s;
      margin: 0rem 2rem;
    
    }
    
    .iconos:hover{
      color: rgb(61, 164, 29)
    }
    
    .iconos a{
      text-decoration: none;
      font-size: 1rem ;
      color: gray
    }
    
    .iconos a:hover{
    color:rgb(61, 164, 29) ;
    }
    
    #letra{
      font-weight: 800;
    }
    
    #ubicacion{      
      font-size: 2.5rem;        
    }
       
    #ubicacion a{  
      color: gray ;
      font-size: 6rem;     
    }
    
    #ubicacion a:hover{
      color: rgb(61, 164, 29) ;
    }
    
    .containers{
      margin: 0rem;
      border: rgb(36, 32, 32);
      background: linear-gradient(to bottom right,white, rgb(61, 164, 29));
      height: 100px;
      width: 1200px;
      position: relative;     
    }
    
    .containers::after{
      
      border: #000000;
      position: absolute;
      bottom: 0px;
      left: 0px;
      background: #000000;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0f9b0f, #000000);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0f9b0f, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
    
      content: ' '; 
      width: 100%;
      height: 100%;
      filter:blur(10px);
      z-index: -1;
    }

        .sobre{     
      height: 250px;
      padding: 1rem;
      margin: 0rem ;      
      }
    
      .ubc{
        padding: 1rem 0rem;
        margin: 1rem 0rem;    
      }
  }


 
@media (min-width: 576px ) and (max-width: 767px) {
 
  nav{
    display: flex; 
    /* align-self: flex-end;   */
    color: black;
    text-decoration: none;
    padding: 3rem 0rem;
    text-shadow: 2px 2px 5px rgb(61, 164, 29);
    font-size:1.2rem;
    font-weight:500;      
  }
  
  h1{
     text-shadow: 2px 2px 5px rgb(61, 164, 29)
  }
  
  h2{
    font-size: 0.5rem;
    text-shadow: 2px 2px 5px rgb(61, 164, 29)
    
  }
  
  .titulo2{
    text-align: center;
    max-width: 100%;  
    text-shadow: 2px 2px 5px rgb(61, 164, 29);
    font-size:3rem;    
    color:white;    
    font-family: 'Lobster', cursive;    
    padding: 2rem 3rem 3rem 3rem;    
  }
  
  #inicio{
    display: flex;
    background: rgb(61, 164, 29);
    padding: 4rem 1rem; ;
    margin: 2rem  0rem 0rem 0rem;
    width: 740px;
    height: 27rem;   
  }
  
  #imginicio{
    padding: 0rem  ;
    height: 330px;
    width: 500px;
   }
  
  #nosotros{
    padding: 0rem 2rem;
    border: 2rem;
    margin-top: 0rem;
    display: flex;
    width: 765px;   
      }
    
  .imgnosotros{
    margin: 1rem 5rem 0rem 0rem;
    width: 300px;
    height: 300px;
    border-style: outset;
    border-width: 5px;   
  }
  
  .lista1::marker{    
    font-size: 1rem;
    color: rgb(61, 164, 29);  
  }
  
  #lista1{
    padding: 3rem 1rem 0rem 2rem;
    font-size: 18px;
  }
  
  #contenedorcontacto{
    display: flex;
    padding: 1rem 0rem;
    border: #272a27;
    width: 765px;
    height: 490px; 
  }
  
  .formulario{    
    margin: 3px 2rem;
    padding: 2px;
    width: 800px;    
  }
  
  .fondoform{    
    height: 450px;
    background-image: url(./assets/img/fondo-verde-42242525.jpg);
    margin: 0rem 1rem;
    padding: 2rem 0rem;
    width: 800px;
  }
  
  .boton{
    display: flex;
    align-items: center;
    justify-content: center;  
    padding: 2rem 0rem 0rem 15rem;
    width: 10rem;
    }
  
   .message{ 
    padding: 0.2rem;
      margin: 0.3rem;
      text-Align: center;
      color: rgb(25, 135, 84);
      font-Weight: bold;
      font-Size:1rem;
   }
    
  #contacto{
     padding: 3rem 3rem 0rem;    
  }
  
  #comunicate{  
    margin: 0rem 1rem;
    padding: 0.5rem;
    border: rgb(36, 32, 32);
    background-color:rgba(239, 239, 239, 0.895);
    height: 450px;
    width: 700px;
    position: relative;
  }
  
  #comunicate::after{    
    border: #000000;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #000000;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0f9b0f, #000000);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0f9b0f, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  
    content: ' ';    
    width: 100%;
    height: 10%;
    filter:blur(10px);
    z-index: -1;
  }
  
  .iconos{
    font-size: 1rem;
    font-weight: 30px;
    color: gray;
    transition: 0.8s;
    margin: 0rem 1rem;  
  }
  
  .iconos:hover{
    color: rgb(61, 164, 29)
  }
  
  .iconos a{
    text-decoration: none;
    font-size: 0.9rem ;
    color: gray
  }
  
  .iconos a:hover{
  color:rgb(61, 164, 29) ;
  }
  
  #letra{
    font-weight: 800;
  }
  
  #ubicacion{    
    font-size: 2rem;   
  }  
  
  #ubicacion a{   
    color: gray ;
    font-size: 6rem;    
  }
  
  #ubicacion a:hover{
    color: rgb(61, 164, 29) ;
  }
  
  .containers{
    margin: 0rem;
    border: rgb(36, 32, 32);
    background: linear-gradient(to bottom right,white, rgb(61, 164, 29));
    height: 100px;
    width: 1200px;
    position: relative;   
  }
  
  .containers::after{    
    border: #000000;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #000000;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0f9b0f, #000000);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0f9b0f, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  
    content: ' ';   
    width: 100%;
    height: 100%;
    filter:blur(10px);
    z-index: -1;
  }
  
  .sobre{   
    height: 250px;
    padding: 1rem;
    margin: 0rem ;    
    }
  
    .ubc{
      padding: 1rem 0rem;
      margin: 1rem 0rem;  
    }
}
  

@media(min-width: 361px ) and (max-width: 575px){

  nav{
    display: flex; 
    align-self: flex-end;  
    color: black;
    text-decoration: none;
    padding: 3rem 2rem;
    text-shadow: 2px 2px 5px rgb(61, 164, 29);
    font-size:1.2rem;
    font-weight:500;      
  }
  
  h1{
    text-shadow: 2px 2px 5px rgb(61, 164, 29)
 }
 
 h2{
   font-size: 0.5rem;
   text-shadow: 2px 2px 5px rgb(61, 164, 29)
   
 }

 .titulo2{
  text-align: center;
  max-width: 100%;
  text-shadow: 2px 2px 5px rgb(61, 164, 29);
  font-size:1.8rem;  
  color:white;  
  font-family: 'Lobster', cursive;  
  padding: 2rem 0rem 3rem 0rem;  
}

  #inicio{    
    display: flex;
    background: rgb(61, 164, 29);
    margin: 2rem  0rem;
    max-width: 100%;
    height: 20rem;
    }
  
  #imginicio{
    padding: 0rem  ;
    height: 250px;
    width: 400px;   
  }
  
  #nosotros{   
    padding: 0rem;
    display: flex;
    width: 570px;
    margin: 0rem;   
  }
    
  .imgnosotros{
    margin: 1rem 0rem 0rem 0rem;
    width: 200px;
    height: 100px;
    border-style: outset;
    border-width: 3px;   
  }
  
  .lista1::marker{    
    font-size: 1rem;
    color: rgb(61, 164, 29);  
  }
  
  #lista1{
    padding: 1rem 0rem 0rem 3rem;
    font-size: 15px;    
  }
  
  #contenedorcontacto{   
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding: 0rem 1rem;
    margin: 1rem 0rem;
    border: #272a27;
    width: 575px;
    height: 750px; 
  }
  
  .formulario{      
    margin: 0rem 1rem;
    padding: 2px;
    width: 280px;    
  }
  
  .fondoform{ 
    height: 370px;
    background-image: url(./assets/img/fondo-verde-42242525.jpg);
    margin: 1rem 3rem ;
    padding: 0rem;
    width: 380px;
  }
  
  .boton{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0rem 0rem 10rem;
    width: 8rem;
    margin: 0rem;
    }
  
   .message{ 
    padding: 0rem;
      margin: 0rem;
      text-Align: center;
      color: rgb(25, 135, 84);
      font-Weight: bold;
      font-Size:0.8rem;
   }  
  #contacto{
    padding: 2rem 2rem 0rem ;    
  }
    
  #comunicate{  
    margin: 0rem 0rem 0rem 0.5rem;
    padding: 1rem;
    border: rgb(36, 32, 32);
    background-color:rgba(239, 239, 239, 0.895);
    height: 370px;
    width: 380px;
    position: relative;
  }
  
  #comunicate::after{
    
    border: #000000;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #000000;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0f9b0f, #000000);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0f9b0f, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  
    content: ' ';    
    width: 100%;
    height: 10%;
    filter:blur(10px);
    z-index: -1;
  }
  
  .iconos{
    font-size: 1rem;
    color: gray;
    transition: 0.8s;
    margin: 0rem 4rem;  
  }
  
  .iconos:hover{
    color: rgb(61, 164, 29)
  }
  
  .iconos a{
    text-decoration: none;
    font-size: 0.9rem ;
    color: gray
  }
  
  .iconos a:hover{
  color:rgb(61, 164, 29) ;
  }
  
  #letra{
    font-weight: 800;
  }
  
  #ubicacion{    
    font-size: 2rem;   
  }
  
  
  #ubicacion a{   
    color: gray ;
    font-size: 4rem;   
  }
  
  #ubicacion a:hover{
    color: rgb(61, 164, 29) ;
  }
  
  .containers{
    display: flex;
    margin: 0rem 0rem 0rem 15rem;
    border: rgb(36, 32, 32);
    background: linear-gradient(to bottom right,white, rgb(61, 164, 29));
    height: 100px;
    max-width: 100%;
    position: relative;  
  }
  
  .containers::after{    
    border: #000000;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #000000;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0f9b0f, #000000);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0f9b0f, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  
    content: ' ';    
    width: 100%;
    height: 100%;
    filter:blur(10px);
    z-index: -1;
  }
  
  .sobre{   
    height: 150px;
    padding: 0rem;
    margin: 1rem;   
    }
  
    .ubc{
      padding: 1rem 0rem;
      margin: 1rem 0rem;  
    }


}

@media (max-width: 360px){

  nav{
    display: flex; 
    align-self: flex-end;  
    color: black;
    text-decoration: none;
    padding: 3rem 2rem;
    text-shadow: 2px 2px 5px rgb(61, 164, 29);
    font-size:1.2rem;
    font-weight:500;      
  }
  
  h1{
    text-shadow: 2px 2px 5px rgb(61, 164, 29)
 }
 
 h2{
   font-size: 0.5rem;
   text-shadow: 2px 2px 5px rgb(61, 164, 29)
   
 }

 .titulo2{
  text-align: center;
  max-width: 100%;
  text-shadow: 2px 2px 5px rgb(61, 164, 29);
  font-size:1.4rem;  
  color:white;  
  font-family: 'Lobster', cursive;  
  padding: 1.8rem 1rem 3rem 1rem;  
}
  #inicio{   
    display: flex;
    background: rgb(61, 164, 29);
    padding: 3.5rem 1rem; ;
    margin: 1rem  1rem;
    width: 360px;
    height: 16rem;    
  }
  
  #imginicio{
    padding: 1rem 0rem  ;
    height: 200px;
    width: 200px;    
  }
  
  #nosotros{    
    width: 360px;  
    margin: 0rem 1rem;    
  }
   
  .imgnosotros{
    margin: 1rem 2rem 0rem 0rem;
    width: 200px;
    height: 300px;
    border-style: outset;
    border-width: 5px;   
  }
   
  .lista1::marker{    
    font-size: 1rem;
    color: rgb(61, 164, 29) ;  
  }
  
  #lista1{
    padding: 1rem 0rem 0rem 0.6rem;
    font-size: 12px;    
  }
  
  #contenedorcontacto{
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding: 0rem 0rem;
    margin: 1rem 1rem 22rem 0.1rem;
    border: #272a27;
    width: 350px;
    height: 390px; 
  }
  
  .formulario{    
    margin: 0rem 1rem;
    padding: 2px;
    width: 140px;    
  }
  
  .fondoform{    
    height: 370px;
    background-image: url(./assets/img/fondo-verde-42242525.jpg);
    margin: 0rem 0rem ;
    padding: 0rem;
    width: 328px;
  }
  
  .boton{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0rem 0rem 10rem;
    width: 8rem;
    margin: 0rem;
    }
  
   .message{ 
    padding: 0rem;
      margin: 0rem;
      text-Align: center;
      color: rgb(25, 135, 84);
      font-Weight: bold;
      font-Size:0.8rem;
   }
  
  #contacto{
     padding: 1rem;    
  }
    
  #comunicate{  
    margin: 1rem 0rem 2rem 0rem;
    padding: 1.5rem;
    border: rgb(36, 32, 32);
    background-color:rgba(239, 239, 239, 0.895);
    height: 350px;
    width: 328px;
    position: relative;     
  }
  
  #comunicate::after{    
    border: #000000;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #000000;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0f9b0f, #000000);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0f9b0f, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
   
    content: ' ';    
    width: 100%;
    height: 10%;
    filter:blur(10px);
    z-index: -1;
  }
  
  .iconos{
    font-size: 1.2rem;
    font-weight: 30px;
    color: gray;
    transition: 0.8s;
    margin: 0rem 0rem 0rem 3rem;  
  }
  
  .iconos:hover{
    color: rgb(61, 164, 29)
  }
  
  .iconos a{
    text-decoration: none;
    font-size: 1rem ;
    color: gray
  }
  
  .iconos a:hover{
  color:rgb(61, 164, 29) ;
  }
  
  #letra{
    font-weight: 700;
  }
  
  #ubicacion{    
    font-size: 1.5rem;   
  }
  
  
  #ubicacion a{    
    color: gray ;
    font-size: 4rem;    
  }
  
  #ubicacion a:hover{
    color: rgb(61, 164, 29) ;
  }
  
  .containers{
    display: flex;
    margin: 0rem;
    border: rgb(36, 32, 32);
    background: linear-gradient(to bottom right,white, rgb(61, 164, 29));
    height: 100px;
    max-width: 80%;
    position: relative;    
   
  }
  
  .containers::after{    
    border: #000000;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #000000;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0f9b0f, #000000);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0f9b0f, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  
    content: ' ';   
    width: 100%;
    height: 100%;
    filter:blur(10px);
    z-index: -2;
  }
  
  .sobre{   
    height: 150px;
    padding: 0rem 3rem;
    margin: 1rem ;    
    }
  
    .ubc{
      padding: 1rem 1rem;
      margin: 1rem 10rem;  
    }
}






